<template>
  <aside
    v-show="!isFullScreen"
    class="w-64 h-screen fixed top-0 z-40 sidebar-parent transition-all duration-1000"
    :class="[ isAsideMobileExpanded ? '-left-64' : 'left-0', isAsideLgActive ? 'block' : 'lg:block' ]"
    style="background-color: #152751;"
  >
    <div class="inner-sidebar">
      <div class="w-full h-auto p-4 flex justify-center">
        <img :src="CCmiddle" alt="School Logo" style="width: 160px; height: auto;">
      </div>

      <div class="menu-sidebar">
        <template v-for="(menuGroup, index) in menu">
          <p v-if="typeof menuGroup === 'string'" :key="`a-${index}`" class="hidden p-3 text-xs uppercase text-gray-800">{{ menuGroup }}</p>
          <aside-menu-list
            v-else
            :key="`b-${index}`"
            :menu="menuGroup"
            @menu-click="menuClick"
          />
        </template>
        <br><br><br>
      </div>
    </div>
  </aside>
</template>

<script>
/* eslint-disable */
import { axiosEdu } from "@/axios/axios";
import { useToast } from 'vue-toastification'
import { computed, ref, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { mdiMenu, mdiForwardburger } from '@mdi/js'
import AsideMenuList from '@/components/AsideMenuList'
import ShowImage from '@/components/ShowImage'
import Field from '@/components/Field'
// import Icon from '@/components/Icon'

import CCmiddle from '../assets/image/icon/cc-middle.png'

export default {
  name: 'AsideMenu',

  components: {
    AsideMenuList,
    ShowImage,
    Field
  },

  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },

  setup () {
    const store = useStore()
    const rowData = reactive([])
    const toast = useToast()
    const isFullScreen = computed(() => store.state.isFullScreen)
    const schoolidentity = reactive({
        id: '',
        name: '',
        website_url: '',
        email: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        telp1: '',
        telp2: '',
        telp3: '',
        telp4: '',
        fax1: '',
        fax2: '',
        description: '',
        file_name: '',
        // department_id: '',
        status: '',
        library: '',
        info1: '',
        info2: '',
        info3: '',
        created_by: '',
        updated_by: ''
    })

    const getData = async () => {
            await axiosEdu
            .get('/get-menu')
            .then(response => {
                // assign state kelass with response data
                rowData.value = response.data.data
                Object.assign(schoolidentity, response.data.data)
                // filteredData.value = response.data.data
                // filterData()
            }).catch(error => {
                // toast.error(error.response.data, { timeout: 5000 })
                console.log(error)
            })
        }
    const isAsideMobileExpanded = computed(() => store.state.isAsideMobileExpanded)

    const isAsideLgActive = computed(() => store.state.isAsideLgActive)

    const asideLgClose = () => {
      store.dispatch('asideLgToggle', false)
    }

    const menuClick = (event, item) => {
      //
    }
    onMounted(()=>{
      getData()
    })

    const darkMode = computed(() => store.state.darkMode)

    return {
      CCmiddle,
      // classDark,
      darkMode,
      isFullScreen,
      isAsideMobileExpanded,
      isAsideLgActive,
      asideLgClose,
      menuClick,
      rowData,
      mdiMenu,
      mdiForwardburger,
      schoolidentity,
      getData
    }
  }
}
</script>

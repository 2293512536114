<template>
  <div class="w-full h-screen bg-transparent fixed top-0 left-0 right-0 z-10 cursor-default" v-show="(dropexpanded)" @click="dropexpanded = false"></div>
  <div class="change-menu hover:text-black dark:hover:text-white transition-colors duration-500 z-20" @click="dropexpanded = !dropexpanded">
    <div>
      <span v-for="data in module" :key="data.code">
        <!-- {{ data.code }} - {{ $store.state.auth.isActiveModule }} -->
        <span v-if="isActiveModule===data.code">{{ data.name }}</span>
      </span>
      &ensp;
    </div>

    <div class="absolute right-1 transition-transform duration-500"  :class="dropexpanded ? 'rot-active' : ''">
      <icon :path="mdiChevronDown" />
    </div>
  </div>

  <div :class="dropexpanded ? 'top-12 transition-position duration-500 opacity-100' : 'transition-position -top-80 duration-500 opacity-0'" class="drop-menu bg-white dark:bg-gray-900 hover:text-black dark:hover:text-white z-20">
    <li v-for="data in module" @click="changeMenu(data)" :key="data.code" class="mb-3" :class="isActiveModule === data.code ? 'active-menu' : ''">
      <icon :path="mdiSchool" size="20" />
      <span>{{ data.name }}</span>
    </li>
  <!-- <li @click="changeMenuAca('aca')" class="mb-3" :class="$store.state.auth.isActiveModule === 'ACA' ? 'active-menu' : ''" v-if="$store.state.auth.modules.includes('ACA')" >
      <icon :path="mdiSchool" size="20" />
      <span>{{ $t('menu.academic') }}</span>
    </li> -->
<!--
    <li @click="changeMenu('fina')" class="mb-3" :class="mainmenu === 'fina' ? 'active-menu' : ''" v-if="checkFinance" >
      <icon :path="mdiFinance" size="20" />
      <span>{{ $t('menu.finance') }}</span>
    </li> -->

    <!-- <li @click="changeMenuHRD('hc')" class="mb-3" :class="mainmenu === 'hc' ? 'active-menu' : ''" v-if="checkHumanCapital" >
      <icon :path="mdiAccountTie" size="20" />
    <span>{{ $t('employee.hc') }}</span>
    </li> -->

    <!-- <li @click="changeMenuCom('bene')"  :class="mainmenu === 'bene' ? 'active-menu' : ''" v-if="checkComboard" >
      <icon :path="mdiComment" size="20" />
      <span>{{ $t('comboard.comboard') }}</span>
    </li> -->

    <!-- <li @click="changeMenuBook('book')" class="mt-3"  :class="mainmenu === 'book' ? 'active-menu' : ''">
      <icon :path="mdiStoreCog" size="20" />
      <span>{{ $t('Room Booking') }}</span>
    </li> -->

   <!-- <div class="change-menu hover:text-black dark:hover:text-white transition-colors duration-500 z-20" @click="dropexpanded = !dropexpanded" > -->
    <!-- <li class="mt-3 relative" v-show="(dropexpanded)" @click="dropexpanded = false" v-if="checkModul" >
        <icon :path="mdiBookAlert" size="20" @click="changeMenuModule('md')"/>
        <span>{{ $t('modul.modul') }}</span>

        <div class="absolute right-1 transition-transform duration-500"  :class="dropexpanded ? 'rot-active' : ''">
          <icon :path="mdiChevronDown" />
        </div>

        <div :class="dropexpanded ? 'top-12 transition-position duration-500 opacity-100' : 'transition-position -top-80 duration-500 opacity-0'" class="drop-menu bg-white dark:bg-gray-900 hover:text-black dark:hover:text-white z-20" style="padding-top: 10px; margin-top: 10px;" >
            <ul class="d-flex flex-column">

                <li class="mb-3" >
                  <icon :path="mdiAccountTie" size="20" />
                  <a :href="urlAdmission.url" target="_blank" class="mb-3" :class="mainmenu === 'ad' ? 'active-menu' : ''" v-if="checkAdmission">
                    {{ $t('modul.admission') }}
                  </a>
                </li>

                <li class="mb-3">
                  <a :href="urlRegis.url" target="_blank" class="mb-3" :class="mainmenu === 'rg' ? 'active-menu' : ''" v-if="checkRegistration">
                    <icon :path="mdiSchool" size="20" /> {{ $t('modul.registration') }}
                  </a>
                </li>

                <li @click="changeMenuSubject('sb')" class="mb-3" :class="mainmenu === 'sb' ? 'active-menu' : ''" v-if="checkSubjectSelection">
                  <icon :path="mdiBookOpenPageVariant" size="20" />{{ $t('modul.subjectselection') }}
                </li>

                <li @click="changeMenuInterest('in')" class="mb-3" :class="mainmenu === 'in' ? 'active-menu' : ''" v-if="checkInterest">
                  <icon :path="mdiSoccer" size="20" />{{ $t('modul.interest') }}
                </li>
            </ul>
          </div>
      </li> -->
      <!-- </div> -->
  </div>
</template>

<script>
  /* eslint-disable */
  import { axiosEdu } from "@/axios/axios";
  import { computed, ref, onBeforeMount,reactive, watchEffect } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { useRouter, useRoute } from 'vue-router'
  import Icon from '@/components/Icon'
  import { mdiChevronDown, mdiChevronUp, mdiSchool,mdiBookOpenPageVariant, mdiSoccer, mdiFinance, mdiAccountTie, mdiComment,mdiStoreCog, mdiBookAlert } from '@mdi/js'
  import CryptoJS from 'crypto-js'

  export default {
    components: {
      Icon
    },
    props: {
      module: {
        type: Array,
        default: () => []
      }
    },
    data :function () {
      return {
        isActiveModule:''
      }
    },
    watch: {
        $route(to, from) {
            if (to.matched.length && to !== from ) {

                this.isActiveModule = localStorage.getItem('isActiveModule')
            }
        }
    },

    setup() {
      const { t } = useI18n()
      const router = useRouter()
      const route = useRoute()
      const allowStatus = ref(false)
      const store = useStore()
      const menuModule = ref(false)
      const hideModal = () => {
        menuModule.value = false
        refresh()
      }
      const showModal = () => {
        menuModule.value = !menuModule.value
      }
      const rowData = reactive([])
      const data = reactive({
          id: '',
          admission: '',
          registration: '',
          updated_by: ''
      })


      // const isActiveModule = ref(localStorage.getItem('isActiveModule'))


      const urlAdmission = reactive({
        url: 'http://103.3.62.193:9191'
      })
      const urlRegis = reactive({
        url: 'http://103.3.62.193:1111'
      })

      const getModule = async () => {
        axiosEdu.get('connect-module')
        .then(response => {
          rowData.value = response.data.data
          data.id = rowData.value.id
          urlAdmission.url = rowData.value.admission
          urlRegis.url = rowData.value.registration
        })
        .catch(error => {
          // Tangani kesalahan jika terjadi
          console.error(error);
        });
      }

      const changeMenu = (data) => {
        const path = '/'+data.redirect
        // if(localStorage.getItem('menu') !== 'fina') {
        //   router.push(path)
        // }else{
        //   router.go()
        // }
        router.push(path)
        // commit('set_isActiveModule', data.code)
        localStorage.setItem('menu', data.code)
        localStorage.setItem('isActiveModule', data.code)
        this.isActiveModule = data.code
      }

      const changeMenuAca = (evt) => {
        localStorage.setItem('menu', evt)
        const path = '/home-academic'
        if(localStorage.getItem('menu') !== 'aca') {
          router.push(path)
        }else{
          router.go()
        }
        router.push(path)
      }

      const changeMenuCom = (evt) => {
        localStorage.setItem('menu', evt)
        const path = '/home-comboard'
        if(localStorage.getItem('menu') !== 'bene') {
          router.push(path)
        }else{
          router.go()
        }
        router.push(path)
      }

      const changeMenuBook = (evt) => {
        localStorage.setItem('menu', evt)
        const path = '/home-booking'
        if(localStorage.getItem('menu') !== 'book') {
          router.push(path)
        }else{
          router.go()
        }
        router.push(path)
      }

      const changeMenuHRD = (evt) => {
        localStorage.setItem('menu', evt)
        const path = '/home-hrd'
        if(localStorage.getItem('menu') !== 'hc') {
          router.push(path)
        }else{
          router.go()
        }
        router.push(path)
      }

      const changeMenuModule = (evt) => {
        const path = '/home-module'
        if(localStorage.getItem('menu') !== 'md') {
          router.push(path)
        }else{
          router.go()
        }
        localStorage.setItem('menu', evt)
        router.push(path)
      }

      const changeMenuSubject = (evt) => {
        const path = '/subject-selection'
        if(localStorage.getItem('menu') !== 'sb') {
          router.push(path)
        }else{
          router.go()
        }
        localStorage.setItem('menu', evt)
        router.push(path)
      }

      const changeMenuInterest = (evt) => {
        const path = '/interest-selection'
        if(localStorage.getItem('menu') !== 'in') {
          router.push(path)
        }else{
          router.go()
        }
        localStorage.setItem('menu', evt)
        router.push(path)
      }

      const mainmenu = ref(localStorage.getItem('menu'))

      const userRole = localStorage.getItem('roles')

      const checkAcademic = ref(false)
      const checkFinance = ref(false)
      const checkHumanCapital = ref(false)
      const checkComboard = ref(false)
      const checkModul = ref(false)
      const checkAdmission = ref(false)
      const checkRegistration = ref(false)
      const checkSubjectSelection = ref(false)
      const checkInterest = ref(false)

      const userCheck = async () => {
        // Axios
        const url = 'check-user-modules'
        const method = 'GET'

        const params = {
          user_id: localStorage.getItem('user_id'),
          loginAS: localStorage.getItem('loginAs')
        }

        await axiosEdu({
              method,
              url,
              params
            })
            .then(response => {
              checkAcademic.value = response.data.academic
              checkFinance.value = response.data.finance
              checkHumanCapital.value = response.data.humanCapital
              checkComboard.value = response.data.comboard
              checkModul.value = response.data.modul
              checkAdmission.value = response.data.admission
              checkRegistration.value = response.data.registration
              checkSubjectSelection.value = response.data.subjectSelection
              checkInterest.value = response.data.interest
            })
            .catch(error => {
              console.log('Error User Check', error)
            })
      }
      // watch(() => store.state.auth.isActiveModule, function() {
      //   let accessModule = []
      //   masterModule.forEach((module) => {
      //   });
      // });

      onBeforeMount(() => {
        userCheck()
      })

      watchEffect(() => {
        // masterModule.value = JSON.parse(localStorage.getItem('moduleList'))
      })

      return {
        getModule,
        menuModule,
        hideModal,
        showModal,
        urlAdmission,
        urlRegis,
        rowData,
        data,
        userRole,
        userCheck,

        checkAcademic,
        checkFinance,
        checkHumanCapital,
        checkComboard,
        checkModul,
        checkAdmission,
        checkRegistration,
        checkSubjectSelection,
        checkInterest,

        t,
        changeMenu,
        changeMenuCom,
        changeMenuBook,
        changeMenuAca,
        changeMenuHRD,
        changeMenuModule,
        changeMenuSubject,
        changeMenuInterest,
        mainmenu,
        mdiChevronDown,
        mdiChevronUp,
        mdiSchool,
        mdiBookOpenPageVariant,
        mdiSoccer,
        mdiFinance,
        mdiAccountTie,
        mdiComment,
        mdiStoreCog,
        mdiBookAlert,
        dropexpanded: ref(false),
        allowStatus,
        // masterModule,
        // isActiveModule
        // admission: ref(false),
        // registration: ref(false),
        // subjectselection: ref(false),
        // interest: ref(false)
      }
    },
  }
</script>

<template>
  <select v-model="locale.bahasa" @change="change()">
    <option value="id" class="dark:bg-gray-700">Indonesia</option>

    <option selected="selected" value="en"  class="dark:bg-gray-700">English</option>
  </select>
</template>

<script>
// import CountryFlag from 'vue-country-flag-next'
import { reactive } from '@vue/reactivity'
export default {
  name: 'LocaleSwitcher',
  component: {
    // CountryFlag
  },
  data () {
    return { options: [{ title: 'ID', url: '../assets/id.png' }, { title: 'EN', url: '../assets/en.png' }] }
  },
  setup () {
    const locale = reactive({
      bahasa: localStorage.getItem('locale')
    })
    const change = async () => {
      localStorage.setItem('locale', locale.bahasa)
      location.reload()
    }
    return {
      locale,
      change
    }
  }
}
</script>

<style>
  select {
    background-color: transparent !important;
    width: 100% !important;
    padding: 0 !important;
    border: none !important;
    outline: none !important;
    position: relative !important;
  }
  option {
    padding: 2px 4px !important;
    border-top-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
</style>

import axios from 'axios'

export function setHeaderToken (token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
}

export function removeHeaderToken () {
  delete axios.defaults.headers.common.Authorization
}

<template>
  <svg viewBox="0 0 24 24" class="inline-block" :class="spanClass">
    <path :d="path" />
  </svg>
</template>

<script>
  import { computed } from 'vue'

  export default {
    name: 'Icon',

    props: {
      path: {
        required: true
      },
      w: {
        type: String,
        default: 'w-5'
      },
      h: {
        type: String,
        default: 'h-5'
      },
      size: {
        type: String,
        default: 'text-3xl'
      }
    },

    setup (props) {
      const spanClass = computed(() => {
        return `inline-flex justify-center items-center ${props.w} ${props.h} ${props.size}`
      })

      return { spanClass }
    }
  }
</script>

<style scoped>
/*svg {*/
/*  transform: rotate(var(--r, 0deg)) scale(var(--sx, 1), var(--sy, 1));*/
/*}*/
path {
  fill: currentColor;
}
</style>

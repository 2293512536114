<script setup>
import { computed } from 'vue'
import {
  mdiCashMinus,
  mdiCashPlus,
  mdiReceipt,
  mdiCreditCardOutline
} from '@mdi/js'
import CardBox from '@/components/CardBox.vue'
import BaseLevel from '@/components/BaseLevel.vue'
// import PillTag from '@/components/PillTag.vue'
import IconRounded from '@/components/IconRounded.vue'

const props = defineProps({
  uploadva: {
    type: Number,
    required: true
  },
  bank: {
    type: String,
    required: true
  },
  payment: {
    type: Number,
    required: true
  },
  transaction: {
    type: String,
    required: true
  }
})

const icon = computed(() => {
  if (props.type === 'withdrawal') {
    return {
      icon: mdiCashMinus,
      type: 'danger'
    }
  } else if (props.type === 'deposit') {
    return {
      icon: mdiCashPlus,
      type: 'success'
    }
  } else if (props.type === 'invoice') {
    return {
      icon: mdiReceipt,
      type: 'warning'
    }
  }

  return {
    icon: mdiCreditCardOutline,
    type: 'info'
  }
})
</script>

<template>
  <CardBox class="mb-6 last:mb-0" is-hoverable>
    <BaseLevel>
      <BaseLevel type="justify-start">
        <IconRounded :icon="icon.icon" :color="icon.type" class="hidden md:mr-6" />
        <div class="text-center space-y-1 md:text-left md:mr-6">
          <h4 class="text-xl">Rp. {{ uploadva }}</h4>
          <p class="">
            <b>{{ bank }}</b>
          </p>
        </div>
      </BaseLevel>
      <div class="text-center md:text-left space-y-2">
        <h4 class="text-xl">Rp. {{ payment }}</h4>
        <p class="">
          <b>{{ transaction }}</b>
        </p>
      </div>
    </BaseLevel>
  </CardBox>
</template>

<template>
  <div
    class="w-full h-screen bg-transparent fixed top-0 left-0 right-0 z-10 cursor-default"
    style="z-index: 995;"
    v-show="(notifdrop || dropexpanded)"
    @click="notifdrop = false, dropexpanded = false"
  ></div>

  <nav
    v-show="isNavBarVisible"
    class="navbar-parent transition-position duration-1000 z-30 left-0"
    :class="[isAsideMobileExpanded ? 'lg:left-0' : 'lg:left-64']"
    :style="(notifdrop || dropexpanded) ? 'z-index: 999; pointer-events: none;' : ''"
  >
    <div class="flex flex-nowrap" :style="[darkMode ? 'background-color: #272727; transition: background-color 1s;' : 'background-color: white; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; transition: background-color 1s;']">
      <!-- Show / Hide Sidebar -->
      <div class="w-max flex items-center pl-4 lg:px-4 lg:border-r lg:border-gray-200 lg:dark:border-gray-700" type="flex" @click.prevent="menuToggleMobile">
        <div class="cursor-pointer transition-transform duration-500" :class="isAsideMobileExpanded ? 'rot-active' : ''">
          <icon
            :w="'w-6'"
            :h="'h-6'"
            :path="mdiBackburger"
            class="text-gray-400 hover:text-black dark:text-gray-500 dark:hover:text-white hidden lg:block"
          />
          <icon
            :w="'w-6'"
            :h="'h-6'"
            :path="mdiDotsVertical"
            class="text-gray-400 hover:text-black dark:text-gray-500 dark:hover:text-white block lg:hidden"
          />
        </div>
      </div>

      <div class="w-full flex justify-between pr-4 h-14">
        <!-- Menu & Searchbar -->
        <nav-bar-item>
          <!-- <main-menu :module="module" /> -->
          <!-- <nav-bar-search class="hidden lg:block"/> -->
        </nav-bar-item>

        <div class="flex items-center w-max h-[100%] relative">
          <!-- Notification -->
          <div class="notif cursor-pointer" :style="notifdrop ? 'pointer-events: all;' : ''">
            <div @click="notifdrop = !notifdrop">
              <icon class="bell" :w="'w-6'" :h="'w-6'" :path="mdiBell"/>
              <div v-if="dataNotif.value" class="badge">{{ notifTotal }}</div>
            </div>

            <nav-notif v-if="notifdrop" :prop-data="dataNotif" @get-data="getData()" class="transform transition ease-in-out duration-1000" :class="notifdrop ? 'opacity-100 origin-top-right scale-100' : 'origin-top-right scale-0 rotate-12 opacity-0'" :style="(dropexpanded || notifdrop) ? 'pointer-events: all;' : ''"/>
          </div>

          <!-- Account -->
          <div
            @click="(dropexpanded = !dropexpanded)"
            class="w-auto h-auto p-1 rounded-3xl hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer flex items-center gap-2"
            :style="dropexpanded ? 'pointer-events: all;' : ''"
          >
            <user-avatar class="w-8 h-8"/>
            <!-- <span class="hidden lg:block">{{ $store.state.auth.user?$store.state.auth.user.name:'' }}</span> -->
            <span class="hidden lg:block">{{ userName.name }}</span>
          </div>

          <!-- Account Dropdown -->
          <div class="drop-menu2" :class="dropexpanded ? 'right-1 lg:right-4 transition-position' : 'transition-position -right-80'" :style="dropexpanded ? 'pointer-events: all;' : ''">
            <div class="p-2" :style="[darkMode ? 'background-color: #121212; transition: background-color 1s;' : 'background-color: white; transition: background-color 1s;']">
              <div class="block lg:hidden bg-sidebar rounded-lg text-white px-2 py-3 mb-3">
                <span>{{ $store.state.auth.user?$store.state.auth.user.name:'' }}</span>
              </div>

              <!-- <li to="/profile" class="mb-3">
                <icon :path="mdiAccount" size="20" />
                <span>{{ $t('menu.profile') }}</span>
              </li> -->

              <li @click.prevent="ChangePass" class="mb-3" style="padding: 2px 4px 2px 16px !important" >
                <icon :path="mdiSwapHorizontal" size="20" />
                <span>{{ $t('menu.changePass') }}</span>
              </li>

              <li class="mb-3" style="padding: 2px 4px 2px 16px !important">
                <icon :path="mdiTranslate" size="20" />
                <LocaleSwitcher />
              </li>

              <li class="mb-3" @click.prevent="toggleLightDark">
                <icon :path="mdiThemeLightDark" size="20" />
                <span v-if="darkMode">{{ $t('menu.lightmode') }}</span>
                <span v-else>{{ $t('menu.darkmode') }}</span>
              </li>

              <li @click.prevent="logout">
                <icon :path="mdiLogout" size="20" />
                <span>{{ $t('menu.logout') }}</span>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Button Close Sidebar Mobile -->
    <div
      class="btn-close-sidebar lg:hidden bg-sidebar transition-position duration-1000"
      :class="[isAsideMobileExpanded ? '-left-20' : 'left-64']"
      @click.prevent="menuToggleMobile"
    >
      <icon :path="mdiBackburger" :w="'w-6'" :h="'h-6'" />
    </div>

    <!-- Background Dark -->
    <div :class="[isAsideMobileExpanded ? 'opacity-0 pointer-events-none origin-left' : 'opacity-50  origin-left']" class="w-full h-screen absolute top-0 lg:hidden bg-black transform transition ease-in-out duration-1000"></div>
  </nav>

        <!-- <div class="flex-1 items-stretch flex h-14 text-black dark:text-white">
            <nav-bar-item type="flex" @click.prevent="menuToggleMobile">
                <icon :path="menuToggleMobileIcon" size="24" class="lg:hidden" />
                <icon :path="menuToggleMobileIcon2" size="24" class="hidden lg:flex" />
            </nav-bar-item> -->
            <!-- <nav-bar-item type="hidden lg:flex xl:hidden" @click.prevent="menuOpenLg">
                <icon :path="mdiMenu" size="24" />
            </nav-bar-item> -->
            <!-- <nav-bar-item>
                <main-menu />
            </nav-bar-item>
            <nav-bar-item>
                <nav-bar-search />
            </nav-bar-item>
        </div> -->

        <!-- <div class="flex-none items-stretch flex h-14 lg:hidden">
            <nav-bar-item @click.prevent="menuNavBarToggle">
                <icon :path="menuNavBarToggleIcon" size="24" />
            </nav-bar-item>
        </div> -->

        <!-- <div
          class="absolute w-screen top-14 left-0 bg-white shadow
            lg:w-auto lg:items-stretch lg:flex lg:flex-grow lg:static lg:border-b-0 lg:overflow-visible lg:shadow-none dark:bg-gray-900"
          :class="[isMenuNavBarActive ? 'block' : 'hidden']"
        >
            <div class="max-h-screen-menu overflow-y-auto lg:overflow-visible lg:flex lg:items-stretch lg:justify-end lg:ml-auto px-6 lg:px-0">
                <LocaleSwitcher />

                <nav-bar-menu has-divider>

                <user-avatar class="w-20 h-20 lg:w-10 lg:h-10 mr-3 inline-flex" />

                <div>
                    <span class="text-2xl lg:text-base dark:text-white dark:hover:text-blue-300">{{ userName.value.namevalue }}</span>
                </div>

                <template #dropdown>
                    <nav-bar-item to="/profile">
                        <nav-bar-item-label :icon="mdiAccount" label="My Profile"/>
                    </nav-bar-item>
                    <nav-bar-item>
                        <nav-bar-item-label :icon="mdiCogOutline" label="Settings"/>
                    </nav-bar-item>
                    <nav-bar-item>
                        <nav-bar-item-label :icon="mdiEmail" label="Messages"/>
                    </nav-bar-item>
                    <nav-bar-menu-divider/>
                    <nav-bar-item @click.prevent="logout" >
                        <nav-bar-item-label :icon="mdiLogout" label="Log Out"/>
                    </nav-bar-item>
                </template>

                </nav-bar-menu>

                <nav-bar-item @click.prevent="toggleLightDark" has-divider is-desktop-icon-only>
                    <nav-bar-item-label :icon="mdiThemeLightDark" label="Light/Dark" is-desktop-icon-only />
                </nav-bar-item> -->
                <!-- <nav-bar-item is-desktop-icon-only>
                  <nav-bar-item-label :icon="mdiLogout" label="Log out" is-desktop-icon-only />
                </nav-bar-item> -->
            <!-- </div>
        </div> -->
</template>

<script>
    /*eslint-disable*/
    import { computed, ref, reactive, onMounted, watchEffect } from 'vue'
    import { useStore } from 'vuex'
    import {
        mdiForwardburger,
        mdiBackburger,
        mdiClose,
        mdiDotsVertical,
        mdiMenu,
        mdiClockOutline,
        mdiCloud,
        mdiCrop,
        mdiAccount,
        mdiSwapHorizontal,
        mdiCogOutline,
        mdiEmail,
        mdiLogout,
        mdiGithub,
        mdiTranslate,
        mdiThemeLightDark,
        mdiBell
    } from '@mdi/js'
    import NavBarItem from '@/components/NavBarItem'
    import NavNotif from '@/components/NavNotif'
    import UserAvatar from '@/components/UserAvatar'
    import Icon from '@/components/Icon'
    import NavBarSearch from '@/components/NavBarSearch'
    import LocaleSwitcher from '@/components/LocaleSwitcher'
    import MainMenu from '@/components/MainMenu'
    import { useRouter } from 'vue-router'
    import { useI18n } from 'vue-i18n'
    import axios from 'axios'
    import CryptoJS from 'crypto-js'

    export default {
        name: 'NavBar',
        components: {
          NavBarSearch,
          UserAvatar,
          NavNotif,
          NavBarItem,
          MainMenu,
          LocaleSwitcher,
          Icon
        },

        props: {
          propUser: {
            type: Object,
            default: () => ({ empty: true })
          },
          module: {
            type: Array,
            default: () => []
          }
        },
        setup (props) {
          const { t } = useI18n()

          const store = useStore()

          const toggleLightDark = () => {
            store.dispatch('darkMode')
          }

          const maindark = ref(localStorage.getItem('darkMode'))

          const isNavBarVisible = computed(() => !store.state.isFullScreen)

          const isAsideMobileExpanded = computed(() => store.state.isAsideMobileExpanded)

          const userName = ref({
            name: '',
            loginAs: ''
          })

          // if (store.state.auth.user != null) {
          //   userName.value.name = store.state.auth.user.name
          //   userName.value = computed(() => store.state.userName)
          // }
          // else {
          //   userName.value = computed(() => store.state.auth.user)
          // }

          const menuToggleMobileIcon = computed(() => isAsideMobileExpanded.value ? mdiBackburger : mdiForwardburger)

          const menuToggleMobileIcon2 = computed(() => isAsideMobileExpanded.value ? mdiForwardburger : mdiBackburger)

          const menuToggleMobile = () => store.dispatch('asideMobileToggle')

          const isMenuNavBarActive = ref(false)

          const menuNavBarToggleIcon = computed(() => isMenuNavBarActive.value ? mdiClose : mdiDotsVertical)

          const menuNavBarToggle = () => {
            isMenuNavBarActive.value = !isMenuNavBarActive.value
          }

          const menuOpenLg = () => {
            store.dispatch('asideLgToggle', true)
          }
          const router = useRouter()

          const ChangePass = () => {
            router.push('/change-password')
           }

          const logout = () => {
            let loginAs = localStorage.getItem('loginAs')
            if(loginAs === 'administrator') {
              store.dispatch('logout').then(() => {
                router.push('/login')
              })
            }
            if(loginAs === 'student' ) {
              store.dispatch('logoutStudent').then(() => {
                router.push('/login-student')
              })
            }
            if(loginAs === 'parent') {
              store.dispatch('logoutParent').then(() => {
                router.push('/login-parent')
              })
            }
            if(loginAs === 'booking') {
              store.dispatch('logoutBooking').then(() => {
                router.push('/login-booking')
              })
            }
          }

          const locale = reactive({
            bahasa: localStorage.getItem('locale')
          })
          const changeLang = async () => {
            localStorage.setItem('locale', locale.bahasa)
            location.reload()
          }



          const darkMode = computed(() => store.state.darkMode)

          const notifTotal = ref()
          const dataNotif = reactive([])
          const getData = async (role, userid) => {
            let url = 'document-expired-get'
            if(role == 'ROLE_PROJECT_MANAGER'){
              url = 'notif-project-manager'
            }
            await axios.get(url, {
              params: {
                is_reminder: 1,
                role: role,
                user_id: userid
              }
            }).then((res) => {
              dataNotif.value = res.data.data
              notifTotal.value = res.data.count
            })
          }
          
          const getRoleUser = async (userid) => {
            await axios.get('check-role-user', {
              params: {
                user_id: userid
              }
            }).then((res) => {
              if(res.data.data == 'Project Manager'){
                getData('ROLE_PROJECT_MANAGER', localStorage.getItem('user_id'))
              }
            })
          }

          const userRole = localStorage.getItem('roles')
          const userCheck = async () => {
            if (userRole !== null) {
              const decryptedMessage = CryptoJS.AES.decrypt(localStorage.getItem('role'), 'amatra1609').toString(CryptoJS.enc.Utf8)
              const myArray = decryptedMessage.split(',')
              for (let i = 0; i < myArray.length; i++) {
                console.log('myArray[i]', myArray[i])
                if (myArray[i] === 'ROLE_ADMIN') {
                  getData('ROLE_ADMIN', localStorage.getItem('user_id'))
                } 
              }

              // Project Manager
              getRoleUser(localStorage.getItem('user_id'))
            }
          }

          onMounted(() => {
            userName.value.name = localStorage.getItem('name')
            userName.value.loginAs = localStorage.getItem('loginAs')
          })

          watchEffect(() => {
            userCheck()
            // userName.value.name = props.propUser.name
          })

            return {
                t,
                userRole,
                userCheck,
                getData,
                dataNotif,
                notifTotal,
                darkMode,
                toggleLightDark,
                isNavBarVisible,
                isAsideMobileExpanded,
                userName,
                menuToggleMobileIcon,
                menuToggleMobileIcon2,
                menuToggleMobile,
                isMenuNavBarActive,
                menuNavBarToggleIcon,
                menuNavBarToggle,
                menuOpenLg,
                mdiMenu,
                mdiClockOutline,
                mdiCloud,
                mdiCrop,
                mdiAccount,
                mdiSwapHorizontal,
                mdiCogOutline,
                mdiTranslate,
                mdiEmail,
                mdiLogout,
                mdiGithub,
                mdiThemeLightDark,
                mdiBell,
                ChangePass,
                logout,
                mdiForwardburger,
                mdiBackburger,
                mdiDotsVertical,
                changeLang,
                locale,
                maindark,
                notifdrop: ref(false),
                dropexpanded: ref(false)
            }
        }
    }
</script>

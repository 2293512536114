<template>
  <div class="icon-pdf" v-if="showImage===true">
    <img :src="image" :alt="name" :class="bg" />
  </div>
  <div class="icon-pdf" v-else>
    <!-- <button> -->
      <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-full h-auto">
        <path d="M100 16.6666V66.6666M100 66.6666L116.667 50M100 66.6666L83.3334 50" stroke="white" stroke-width="14.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M41.6667 100V66.6666C41.6667 49.9166 41.6667 36.0833 66.6667 33.6666M158.333 100V66.6666C158.333 49.9166 158.333 36.0833 133.333 33.6666M58.3333 100C25 100 25 114.917 25 133.333V141.667C25 164.667 25 183.333 66.6667 183.333H133.333C166.667 183.333 175 164.667 175 141.667V133.333C175 114.917 175 100 141.667 100C133.333 100 131 101.75 126.667 105L118.167 114C115.83 116.486 113.009 118.467 109.877 119.821C106.746 121.175 103.37 121.873 99.9583 121.873C96.5466 121.873 93.171 121.175 90.0395 119.821C86.908 118.467 84.0869 116.486 81.75 114L73.3333 105C69 101.75 66.6667 100 58.3333 100Z" stroke="white" stroke-width="14.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    <!-- </button> -->
  </div>
</template>
<script>
/* eslint-disable */
import { computed, ref, onMounted, watchEffect } from 'vue'

export default {
  name: 'ShowImage',
  props: {
    imageName: String,
    bg: {
      type: String,
      default: 'block'
    },
    api: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const image = computed(() =>
      props.imageName ? props.imageName : ''
    )
    const showImage = ref(false)
    const name = computed(() => (props.imageName ? props.imageName : ''))
    const extName = ['pdf']
    function checkImage (imageName) {
      let imagePath = ''
      try {
        const ext = imageName.split('.').pop()
        if (extName.includes(ext)) {
          imagePath = imageName
        }
      } catch (e) {}
      return imagePath
    }

    onMounted(() => {})
    watchEffect(() => {
      if (props.imageName !== undefined && props.imageName !== null) {
        const ext = props.imageName.split('.').pop()
        if (extName.includes(ext)) {
          showImage.value = false
        } else {
          showImage.value = true
        }
      }
    })
    return {
      name,
      image,
      showImage,
      checkImage,
      onMounted
    }
  }
}
</script>
<style scoped>
  .background-logo{
    width: 3.5em;
    height: auto;
    padding: 10px;
    background-color: #FF512F;
    border-radius: 0%;
    cursor: pointer;
  }
</style>

<template>
  <!-- <control ref="root" placeholder="Search" ctrl-k-focus transparent /> -->
  <div class="search-bar">
    <div class="search-button border-gray-400 dark:border-gray-500" :class="dropexpanded ? 'flex border-act' : ''">
      <icon :path="mdiMagnify" class="transition-colors" :class="dropexpanded ? 'text-[#FD841F]' : 'text-gray-400 dark:text-gray-500'" @click="dropexpanded = !dropexpanded"/>
      <input type="text" :placeholder="searchBar" :class="dropexpanded ? 'content-search' : 'content-search-none'">
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  // import Control from '@/components/Control'
  import { computed, ref } from 'vue'
  import Icon from '@/components/Icon'
  import { mdiMagnify } from '@mdi/js'
  import { useI18n } from 'vue-i18n'

  export default {
    name: 'NavBarSearch',
    components: {
      // Control
      Icon
    },
    setup() {
      const { t } = useI18n()
      const searchBar = t('menu.search')

      return {
        t,
        searchBar,
        mdiMagnify,
        dropexpanded: ref(true)
      }
    }
  }
</script>

<template>
    <div class="flex justify-center items-center rounded-full border-2 border-gray-500 shadow-lg bg-transparent">
        <img src="https://avatars.dicebear.com/api/avataaars/example.svg?options[top][]=shortHair&options[accessoriesChance]=93" :alt="name" class="rounded-full h-full w-full object-cover" />
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, watchEffect } from 'vue'

export default {
  name: 'UserAvatar',
  props: {
    username: String,
    bg: {
      type: String,
      default: 'block'
    },
    api: {
      type: String,
      default: 'api/avataaars'
    }
  },
  setup (props) {
    const store = useStore()
    const doesFileExist = (urlToFile) => {
      const xhr = new XMLHttpRequest()
      xhr.open('HEAD', urlToFile, false)
      xhr.send()
      if (xhr.status === '404') {
        return false
      } else {
        return true
      }
    }

    const avatar = computed(() => props.username ? checkImage(props.username) : store.state.userAvatar)

    const name = computed(() => props.username ? props.username : store.state.userName)

    function checkImage (username) {
      let imagePath = ''
      try {
        imagePath = require(`../assets/${username}`)
      } catch (e) {
      }
      return imagePath
    }
    watchEffect(() => {
    })

    return {
      name,
      doesFileExist,
      avatar
    }
  }
}
</script>

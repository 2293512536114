<template>
  <!-- Loading -->
  <div class="vld-parent">
    <loading v-model:active="isLoading" :is-full-page="true" :height="128" :width="128" />
  </div>

  <div class="notif-modal" :style="[darkMode ? 'background-color: #121212;' : 'background-color: #f0f0f0;']">
    <div class="notif-head">
      Notification
    </div>

    <div class="notif-content">
      <div v-if="dataNotif.value === null" class="w-full h-full flex justify-center items-center">
        No Notifications
      </div>

      <div v-else class="notif-value bg-white dark:bg-gray-700" v-for="(data) in dataNotif.value" v-bind:key="data.id" @click="notifClick(data)">
        <div class="flex flex-nowrap gap-2">
          <div class="notif-icon"><icon :w="'w-6'" :h="'h-6'" :path="mdiEmail"/></div>
          <div class="notif-mail" >
            {{ data.info }}
          </div>
        </div>

        <span class="notif-time">{{data.created_at}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  /*eslint-disable*/
  import { mdiEmail } from '@mdi/js'
  import { watchEffect, ref, computed } from '@vue/runtime-core'
  import Icon from '@/components/Icon'
  import { useRouter, useRoute } from "vue-router";
  import { useStore } from 'vuex'
  import 'vue-loading-overlay/dist/vue-loading.css'
  import Loading from 'vue-loading-overlay'
  import axios from 'axios'

    export default {
    props: {
       propData: {
         type: Object,
         default: () => ({ empty: true })
       }
    },
    components: {
      Icon,
      Loading,
    },
    data: function () {
        return {
        }
    },
    setup (props,  { emit }) {
      const store = useStore()
      const darkMode = computed(() => store.state.darkMode)
      const dataNotif = ref()
      const isLoading = ref(false)
      const router = useRouter()
      const route = useRoute();

      watchEffect(() => {
        dataNotif.value = props.propData
      })

      const notifClick = (data) => {
        if (data.type === 't_change_request' || data.type === 't_timeline') {
          localStorage.setItem('id_project', data.project_id)

          if(data.type === 't_timeline') {
            localStorage.setItem('id_notif', 4)
          } else {
            localStorage.setItem('id_notif', data.id)
          }

          if(route.name == 'project'){
            router.push('/dashboard')
          } else {
            router.push('/project')
          }
        } else if (data.type === 'tb_vendor') {
          localStorage.setItem('id_notif', data.vendor_id)

          storeNotifReminder(data.id)
        }
      }
      
      const storeNotifReminder = async (data) => {
        isLoading.value = true

        const url = 'store-expired-notif'
        const method = 'POST'
        const params = {
          'id': data
        }

        await axios({
          method,
          url,
          params
        })
        .then(response => {
          emit('get-data', response.data.data)
          console.log('route.query.to', route.name)
          if(route.name == 'vendor'){
            router.push('/dashboard')
          } else {
            router.push('/vendor')
          }

          // if (response.data.success) {
          //   toast.success(response.data.message)
          // } else {
          //   toast.error(response.data.message)
          // }
          // isLoading.value = false
        })
        .catch(error => {
          isLoading.value = false
          console.log(error.message)
        })
      }

      return {
        mdiEmail,
        dataNotif,
        notifClick,
        store,
        darkMode,
        storeNotifReminder,
        isLoading
      }
    }
  }
</script>

import { axiosEdu } from '@/axios/axios'
import store from '.'
import { setHeaderToken, removeHeaderToken } from '../utils/auth'
export default {
  state: {
    isAuthenticated: false,
    user: null,
    student: null,
    parent: null,
    employee_id: null,
    moduleList: [],
    isActiveModule: ''
  },
  mutations: {
    set_user (state, data) {
      state.user = data
      state.isAuthenticated = true
    },
    set_booking (state, data) {
      state.user = data
      state.isAuthenticated = true
    },
    set_student (state, data) {
      state.student = data
      state.isAuthenticated = true
    },
    set_parent (state, data) {
      state.parent = data
      state.isAuthenticated = true
    },
    reset_student (state) {
      state.student = null
      state.isAuthenticated = false
    },
    reset_parent (state) {
      state.parent = null
      state.isAuthenticated = false
    },
    reset_user (state) {
      state.user = null
      state.isAuthenticated = false
    },
    reset_booking (state) {
      state.user = null
      state.isAuthenticated = false
    },
    set_moduleList (state, data) {
      state.moduleList = data
    },
    reset_moduleList (state) {
      state.moduleList = []
    },
    set_isActiveModule (state, data) {
      state.isActiveModule = data
    },
    reset_isActiveModule (state, data) {
      state.isActiveModule = ''
    }
  },
  getters: {
    isAuthenticated (state) {
      return state.isAuthenticated
    },
    user (state) {
      return state.user
    },
    employee (state) {
      return state.employee_id
    },
    moduleList (state) {
      return state.moduleList
    }
  },
  actions: {
    login ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        axiosEdu.post('auth/login/', data)
          .then(response => {
            const token = response.data.access_token
            localStorage.setItem('token', token)
            localStorage.setItem('user_id', response.data.user.id)
            localStorage.setItem('username', response.data.user.username)
            localStorage.setItem('name', response.data.user.name)
            localStorage.setItem('roles', response.data.roles)
            localStorage.setItem('loginAs', 'administrator')

            localStorage.setItem('menu', response.data.get_menu)

            setHeaderToken(token)
            commit('set_user', response.data.user)
            store.dispatch('get_user', token)
            commit('set_moduleList', response.data.modules_code)
            localStorage.setItem('moduleList', JSON.stringify(response.data.modules_code))
            if (response.data.modules_code.length > 0) {
              commit('set_isActiveModule', response.data.modules_code[0].code)
              localStorage.setItem('isActiveModule', response.data.modules_code[0].code)
            }
            resolve(response)
          })
          .catch(err => {
            commit('reset_user')
            commit('reset_moduleList')
            commit('reset_isActiveModule')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    loginstudent ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        axiosEdu.post('auth/log-student/', data)
          .then(response => {
            const token = response.data.access_token
            localStorage.setItem('token', token)
            localStorage.setItem('user_id', response.data.user.id)
            localStorage.setItem('username', response.data.user.username)
            localStorage.setItem('email', response.data.user.email)
            localStorage.setItem('name', response.data.user.name)
            localStorage.setItem('dept_id', response.data.user.department_id)
            localStorage.setItem('roles', 'ROLE_STUDENT,ROLE_CANDIDATE_STUDENT')
            localStorage.setItem('loginAs', 'student')
            setHeaderToken(token)
            // commit('reset_user')
            commit('set_student', response.data.user)
            store.dispatch('get_student', token)
            // this.get_student(token)
            resolve(response)
            // this.reload()
          })
          .catch(err => {
            commit('reset_student')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    loginparent ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        axiosEdu.post('auth/log-parent/', data)
          .then(response => {
            const token = response.data.access_token
            localStorage.setItem('token', token)
            localStorage.setItem('user_id', response.data.user.id)
            localStorage.setItem('username', response.data.user.username)
            localStorage.setItem('name', response.data.user.name)
            localStorage.setItem('email', response.data.user.email)
            localStorage.setItem('roles', 'ROLE_PARENT,ROLE_FAMILY')
            localStorage.setItem('loginAs', 'parent')
           // localStorage.setItem('student_id', response.data.id)
            setHeaderToken(token)
            // commit('reset_user')
            commit('set_parent', response.data.user)
            store.dispatch('get_parent', token)
            // this.get_parent(token)
            resolve(response)
            // this.reload()
          })
          .catch(err => {
            commit('reset_parent')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    loginbooking ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        axiosEdu.post('auth/log-booking/', data)
          .then(response => {
            const token = response.data.access_token
            localStorage.setItem('token', token)
            localStorage.setItem('user_id', response.data.user.id)
            localStorage.setItem('username', response.data.user.username)
            localStorage.setItem('name', response.data.user.name)
            localStorage.setItem('email', response.data.user.email)
            localStorage.setItem('roles', response.data.roles)
            localStorage.setItem('loginAs', 'booking')

            setHeaderToken(token)
            // commit('reset_booking')
            commit('set_booking', response.data.user)
            store.dispatch('get_booking', token)
            // this.get_user(token)
            resolve(response)
            // this.reload()
          })
          .catch(err => {
            commit('reset_booking')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    async get_booking ({ commit }) {
      if (!localStorage.getItem('token')) {
        return
      }
      try {
        const response = await axiosEdu.get('users/' + localStorage.getItem('user_id'))
        commit('set_booking', response.data.data)
      } catch (error) {
        commit('reset_booking')
        removeHeaderToken()
        localStorage.removeItem('token')
        return error
      }
    },
    async get_user ({ commit }) {
      if (!localStorage.getItem('token')) {
        return
      }
      try {
        const response = await axiosEdu.get('users/' + localStorage.getItem('user_id'))
        commit('set_user', response.data.data)
      } catch (error) {
        commit('reset_user')
        removeHeaderToken()
        localStorage.removeItem('token')
        return error
      }
    },
    async get_student ({ commit }) {
      if (!localStorage.getItem('token')) {
        return
      }
      try {
        const response = await axiosEdu.get('students/' + localStorage.getItem('user_id'))
        commit('set_student', response.data.data)
      } catch (error) {
        commit('reset_student')
        removeHeaderToken()
        localStorage.removeItem('token')
        return error
      }
    },
    async get_parent ({ commit }) {
      if (!localStorage.getItem('token')) {
        return
      }
      try {
        const response = await axiosEdu.get('parents/' + localStorage.getItem('user_id'))
        commit('set_parent', response.data.data)
      } catch (error) {
        commit('reset_parent')
        removeHeaderToken()
        localStorage.removeItem('token')
        return error
      }
    },
    logout ({ commit }) {
      return new Promise((resolve) => {
        commit('reset_user')
        localStorage.removeItem('token')
        localStorage.removeItem('user_id')
        localStorage.removeItem('checkall')
        localStorage.clear()
        removeHeaderToken()
        resolve()
      })
    },
    logoutStudent ({ commit }) {
      return new Promise((resolve) => {
        commit('reset_student')
        localStorage.removeItem('token')
        localStorage.removeItem('user_id')
        localStorage.removeItem('checkall')
        localStorage.clear()
        removeHeaderToken()
        resolve()
      })
    },
    logoutParent ({ commit }) {
      return new Promise((resolve) => {
        commit('reset_parent')
        localStorage.removeItem('token')
        localStorage.removeItem('user_id')
        localStorage.removeItem('checkall')
        localStorage.clear()
        removeHeaderToken()
        resolve()
      })
    },
    logoutBooking ({ commit }) {
      return new Promise((resolve) => {
        // commit('reset_parent')
        localStorage.clear()
        removeHeaderToken()
        resolve()
      })
    }
  }
}


/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
import {
	mdiViewDashboard,
	mdiLightbulbOnOutline,
	mdiFormatListNumbered,
	mdiFileDocumentMultipleOutline,
	mdiAlertOutline,
	mdiFileDocumentCheckOutline,
	mdiBadgeAccountOutline,
	mdiAccountHardHatOutline,
	mdiAccountGroupOutline,
	mdiTablePlus,
	mdiAccountStarOutline,
	// mdiCardAccountPhoneOutline,
	mdiTableCog,
	mdiCog,
	mdiClipboardListOutline,
	mdiFileDocumentAlertOutline,
	mdiFileDocumentMultiple,
	mdiEmail
} from '@mdi/js'
import CryptoJS from 'crypto-js'
import { ref } from 'vue'

const userRole = localStorage.getItem('roles')
const checkUser = ref(0)
if (userRole !== null) {
	const decryptedMessage = CryptoJS.AES.decrypt(localStorage.getItem('role'), 'amatra1609').toString(CryptoJS.enc.Utf8)
	const myArray = decryptedMessage.split(',')
	for (let i = 0; i < myArray.length; i++) {
		if (myArray[i] === 'ROLE_ADMIN' || myArray[i] === 'ROLE_PROJECT_MANAGER' || myArray[i] === 'ROLE_PROJECT_SECRETARY') {
			checkUser.value = 1
		}
	}
}

let Examples = [
	{
		label: 'Master',
		subLabel: 'Submenus Master',
		icon: mdiTablePlus,
		menu: [
			{
				to: '/project-type',
				label: 'Project Type',
				icon: mdiLightbulbOnOutline
			},
			{
				to: '/aspect-type',
				label: 'Aspect Type',
				icon: mdiFormatListNumbered
			},
			{
				to: '/vendor-type',
				label: 'Vendor Type',
				icon: mdiBadgeAccountOutline
			},
			{
				to: '/vendor',
				label: 'Vendor',
				icon: mdiAccountHardHatOutline
			},
			{
				to: '/legal-document',
				label: 'Legal Document',
				icon: mdiFileDocumentCheckOutline
			},
			// {
			// 	to: '/vendor-type-vendor',
			// 	label: 'Vendor Type Vendor',
			// 	icon: mdiAccountHardHatOutline
			// },
			// {
			// 	to: '/vendor-legal-doc',
			// 	label: 'Vendor Legal Doc',
			// 	icon: mdiAccountHardHatOutline
			// },
			{
				to: '/vendor-status',
				label: 'Vendor Status',
				icon: mdiAccountStarOutline
			},
			{
				to: '/project-team-role',
				label: 'Project Team Role',
				icon: mdiAccountGroupOutline
			},
			// {
			// 	to: '/contact-person',
			// 	label: 'Contact Person',
			// 	icon: mdiCardAccountPhoneOutline
			// },
			{
				to: '/template-email',
				label: 'Email Template',
				icon: mdiEmail
			},
			{
				to: '/issue-type',
				label: 'Issue Type',
				icon: mdiAlertOutline
			}
		]
	},
	// {
	// 	label: 'Email',
	// 	subLabel: 'Submenus Email',
	// 	icon: mdiTableCog,
	// 	menu: [
	// 		{
	// 			to: '/template-email',
	// 			label: 'Email Template',
	// 			icon: mdiEmail
	// 		}
	// 		// ,
	// 		// {
	// 		// 	to: '/project-pitching',
	// 		// 	label: 'Project pitching',
	// 		// 	icon: mdiAccountHardHatOutline
	// 		// }
	// 	]
	// },
	{
		label: 'Transaction',
		subLabel: 'Submenus Transaction',
		icon: mdiTableCog,
		menu: [
			{
				to: '/project',
				label: 'Project',
				icon: mdiLightbulbOnOutline
			},
			{
				to: '/project-pitching',
				label: 'Project Pitching',
				icon: mdiFormatListNumbered
			},
			{
				to: '/project-document',
				label: 'Project Document',
				icon: mdiFileDocumentMultipleOutline
			}
		]
	},
	{
		label: 'Settings',
		subLabel: 'Submenus Settings',
		icon: mdiCog,
		menu: [
			// {
			// 	to: '/dashboard',
			// 	label: 'Users',
			// 	icon: mdiAccountGroupOutline
			// },
			{
				to: '/id-setting',
				label: 'ID Setting',
				icon: mdiBadgeAccountOutline
			},
			{
				to: '/activity-for-meeting',
				label: 'Activity For Meeting',
				icon: mdiClipboardListOutline
			},
			{
				to: '/document-expired-reminder',
				label: 'Document Expired Reminder',
				icon: mdiFileDocumentAlertOutline
			}
		]
	}
]

if (checkUser.value === 0) {
	Examples = [
		{
			label: 'Master',
			subLabel: 'Submenus Master',
			icon: mdiTablePlus,
			menu: [
				{
					to: '/vendor',
					label: 'Vendor',
					icon: mdiAccountHardHatOutline
				},
				{
					to: '/vendor-status',
					label: 'Vendor Status',
					icon: mdiAccountStarOutline
				}
			]
		},
		{
			label: 'Transaction',
			subLabel: 'Submenus Transaction',
			icon: mdiTableCog,
			menu: [
				{
					to: '/project',
					label: 'Project',
					icon: mdiLightbulbOnOutline
				},
				{
					to: '/project-pitching',
					label: 'Project Pitching',
					icon: mdiFormatListNumbered
				},
				{
					to: '/project-document',
					label: 'Project Document',
					icon: mdiFileDocumentMultiple
				}
			]
		}
	]
}

const menuAcademicAll = [
	'General',
	[
		{
			to: '/dashboard',
			icon: mdiViewDashboard,
			label: 'sidebar.dashboard'
		}
	],
	'Examples', Examples
]

export default menuAcademicAll

<script setup>
    import { computed } from 'vue'
    import { useStore } from 'vuex'

    const store = useStore()

    const isFullScreen = computed(() => store.state.isFullScreen)
</script>

<template>
        <!-- :class="[ isFullScreen ? 'flex h-screen items-center justify-center' : 'py-6' ]" -->
    <section class="main-section" :class="[ isFullScreen ? 'flex h-screen items-center justify-center' : 'mt-6 h-auto' ]">
        <slot />
    </section>
</template>

<template>
  <li class="px-3" :class="isSubmenuList ? 'mb-0' : 'mb-1'">
    <component
      :is="componentIs"
      :to="itemTo"
      :href="itemHref"
      :target="itemTarget"
      v-slot="vSlot"
      @click="menuClick"
    >
    <div :style="isSubmenuList ? 'border-left: 1px solid #FD841F; width: calc(100% - 5px); margin-left: 5px; padding: 4px 0' : ''">
      <div class="flex flex-nowrap items-center gap-2 z-40 px-2 cursor-pointer clear-left hover:text-white transition-all duration-500" :class="vSlot && vSlot.isExactActive ? styleActive : styleInactive">
        <div class="side-icon">
          <icon
            v-if="item.icon"
            :path="item.icon"
            :style="hasDropdown && isDropdownActive ? 'color: #FD841F;' : ''"
          />
        </div>

        <div class="side-label">
          <span :class="hasDropdown && isDropdownActive ? 'text-white' : ''" v-text="$t(item.label)" />
        </div>

        <div class="side-drop transition-transform duration-500" :class="isDropdownActive ? 'rot-side' : ''">
          <icon
            v-if="hasDropdown"
            :class="hasDropdown && isDropdownActive ? 'text-white' : ''"
            :path="mdiChevronRight"
            :w="'w-5'"
            :h="'h-5'"
          />
        </div>
      </div>
    </div>
    </component>

    <div class="menu-child" :class="isDropdownActive ? 'droping' : 'dropup'">
      <aside-menu-list
        v-if="hasDropdown"
        :menu="item.menu"
        is-submenu-list
      />
    </div>
  </li>
</template>

<script>
import { defineAsyncComponent, ref, computed } from 'vue'
import { mdiChevronRight } from '@mdi/js'
import Icon from '@/components/Icon'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
  name: 'AsideMenuItem',
  components: {
    AsideMenuList: defineAsyncComponent(() => import('@/components/AsideMenuList')),
    Icon
  },
  emits: ['menu-click'],
  props: {
    item: Object,
    isSubmenuList: Boolean
  },
  setup (props, { emit }) {
    const { t } = useI18n()

    const store = useStore()

    const isDropdownActive = ref(false)

    const componentIs = computed(() => props.item.to ? 'router-link' : 'a')

    const hasDropdown = computed(() => !!props.item.menu)

    // const dropdownIcon = computed(() => isDropdownActive.value ? mdiMinus : mdiPlus)

    const itemTo = computed(() => props.item.to || null)

    const itemHref = computed(() => props.item.href || null)

    const itemTarget = computed(() => componentIs.value === 'a' && props.item.target ? props.item.target : null)

    const menuClick = event => {
      emit('menu-click', event, props.item)

      if (hasDropdown.value) {
        isDropdownActive.value = !isDropdownActive.value
      }
    }

    const darkMode = computed(() => store.state.darkMode)

    const styleActive = 'sidebar-active'

    const styleInactive = 'text-gray-400 dark:text-gray-500 py-2'

    return {
      t,
      darkMode,
      isDropdownActive,
      componentIs,
      hasDropdown,
      // dropdownIcon,
      mdiChevronRight,
      itemTo,
      itemHref,
      itemTarget,
      menuClick,
      styleActive,
      styleInactive
    }
  }
}
</script>

<template>
  <!-- Loading -->
  <div class="vld-parent">
    <loading v-model:active="isLoading" :is-full-page="true" :height="128" :width="128"/>
  </div>

  <main-section>
    <!-- <div class="flex bg-gr ay-100 dark:bg-gray-600 text-black w-full h-screen transition-position duration-700"> -->
      <!-- <span class="text-bold"><img src="../assets/logo-amatra.png" alt="logo"></span> -->
      <div class="full-page-container">

        <!-- Content Left -->
        <div class="dashboard-container">
          <!-- Field Cut Off Date -->
          <div class="cutoff-container">
            <field class="cutoff" :label="$t('outstanding.cutoff')">
              <datepicker v-model="filter.cutoff" :enableTimePicker="false" class="date-picker"></datepicker>
            </field>
          </div>

          <!-- Field Category -->
          <div class="category-container">
            <span>Category</span>
            <div class="select-box">
              <check-radio-picker v-model="filter.type" :options="{ 1: $t('vastudent.siswa')}" @change="changeValue()"></check-radio-picker>
              <check-radio-picker v-model="filter.typecalon" :options="{ 1: $t('vastudent.calonsiswa')}" @change="changeValue()"></check-radio-picker>
            </div>
          </div>

          <hr class="divider">

          <div class="payment-dashboard">
            <field label="Payment Reconciliation">
              <CardBoxTransaction
                v-for="(data, index) in rowDataUploadVA.value"
                :key="index"
                :uploadva="data.uploadva"
                :payment="data.payment"
                :bank="$t('dashboard.bank')"
                :transaction="$t('dashboard.transaction')"
                :class="data.same ? 'payment-box' : 'invoice-box'"
              />
            </field>
          </div>

          <field label="Top 20 Debtors">
            <div class="debtors-container">
              <CardBoxSiswa
                v-for="(data, index) in rowDataSiswa.value"
                :key="index"
                :name="data.name"
                :nis="data.nis"
                :kelas="data.class"
                :value="data.value"
                :cutoff="filter.cutoff"
                :id="data.id"
                :category_id="data.category_id"
                :department_id="data.department_id"
                :jml_inv="data.jml_inv"
                :min_inv="data.min_inv"
                :max_inv="data.max_inv"
              />
            </div>
          </field>
        </div>

        <!-- Content Right -->
        <div class="outstanding-wrapper">
          <div class="dashboard-container">
            <field label="Outstanding Composition">
              <vue3-chart-js v-bind="{ ...pieChart }" ref="chartRefPie" style="max-height: 300px;" class="w-auto max-w-[500px]"/>
            </field>
          </div>

          <div class="dashboard-container">
            <field label="Outstanding Analysis">
            <vue3-chart-js v-bind="{ ...barChart }" ref="chartRefBar" :height="250" style="max-height: 450px;" />
            </field>
          </div>
        </div>
      </div>

    <!-- </div> -->
    <div class="rate-container">
      <div class="w-full mb-5">
        <div class="filter-content">
          <field label="Collectible Rate (In %)">
            <div class="grid grid-cols-3 gap-4">
              <control type="number" v-model="filter.chartfail" @change="changeValue()" />
              <control type="number" v-model="filter.chartwarning" @change="changeValue()" />
              <control type="number" v-model="filter.chartsuccess" @change="changeValue()" />
            </div>
          </field>
        </div>
      </div>
      <div class="grid grid-cols-4 gap-4" v-if="rowData.value">
        <div v-for="(data, index) in rowData.value" v-bind:key="index">
          <canvas :id="data.id_canvas" :style="'max-height: 150px !important; max-width: 300px !important;'"></canvas>
        </div>
      </div>
    </div>
  </main-section>
</template>

<script>
  /* eslint-disable */
  import { axiosEdu } from '@/axios/axios'
  import {
    computed,
    ref,
    onMounted,
    onBeforeMount,
    reactive,
    watch
  } from 'vue'
  import { useStore } from 'vuex'
  import {
    mdiAccountMultiple,
    mdiCartOutline,
    mdiChartTimelineVariant,
    mdiFinance,
    mdiMonitorCellphone,
    mdiReload,
    mdiGithub,
    mdiMagnify
  } from '@mdi/js'
  import Chart from 'chart.js/auto'
  import * as chartConfig from '@/components/Charts/chart.config'
  import Datepicker from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'
  import Field from '@/components/Field'
  import MainSection from '@/components/MainSection'
  import ChartDataLabels from 'chartjs-plugin-datalabels'
  import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100'
  import CheckRadioPicker from '@/components/CheckRadioPicker'
  // import JbButton from '@/components/JbButton'
  import { useI18n } from 'vue-i18n'
  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'
  import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
  import { useRouter } from 'vue-router'
  // import CardComponent from '@/components/CardComponent'
  import Control from '@/components/Control'
  import CardBoxTransaction from '@/components/CardBoxTransaction'
  import CardBoxSiswa from '@/components/CardBoxSiswa'
  export default {
    name: 'Home',
    components: {
      Datepicker,
      Field,
      MainSection,
      CheckRadioPicker,
      CardBoxTransaction,
      // JbButton,
      Loading,
      Vue3ChartJs,
      Control,
      CardBoxSiswa
    },
    setup () {
      const titleStack = ref(['Admin', 'Dashboard'])
      const chartData = ref(null)
      const isAsideMobileExpanded = computed(() => store.state.isAsideMobileExpanded)
      const { t } = useI18n()
      const fillChartData = () => {
        chartData.value = chartConfig.sampleChartData()
      }
      const isLoading = ref(false)
      const rowData = reactive([])
      const rowDataSiswa = reactive([])
      const rowDataUploadVA = reactive([])

      const filter = reactive({
        // cutoff: '2023-01-01',
        cutoff: new Date(Date.now()).toISOString(),
        type: true,
        typecalon: true,
        chartfail: 70,
        chartwarning: 20,
        chartsuccess: 10,
        created_by: localStorage.getItem('username')
      })

      // Pie Chart
      const dataBar = ref([])
      const chartRefPie = ref(null)
      const groupFin = ref()
      const finLabel = ref([])
      const pieChart = {
        type: 'pie',
        plugins: [ChartDataLabels],
        options: {
          plugins: {
            legend: {
              position: 'left'
            },
            datalabels: {
              formatter: (value, ctx) => {
                let sum = 0
                const dataArr = ctx.chart.data.datasets[0].data
                // eslint-disable-next-line array-callback-return
                dataArr.map(data => {
                    sum += data
                })
                const percentage = (value * 100 / sum).toFixed(1) + '%'
                return percentage
              },
              color: 'white',
              textAlign: 'center',
              font: {
                weight: 'bold',
                size: 14
              }
            },
            responsive: true
          },
          responsive: true,
          borderWidth: 1,
          borderColor: '#ffffff',
          hoverOffset: 10,
          // events: ['click'],
          onClick: function (evt, item) {
            klikDataChart(this.data.labels[item[0].index])
            groupFin.value = this.data.labels[item[0].index]
            // redirectMenu()
          },
          layout: {
            padding: {
              top: 10,
              bottom: 10
            }
          }
        },
        data: {
          labels: finLabel,
          datasets: [
            {
              backgroundColor: ['#bc8a5f', '#8b5e34', '#603808', '#f3d5b5'],
              data: dataBar.value
            }
          ]
        }
      }

      // Bar Chart
      const chartRefBar = ref(null)
      const router = useRouter()
      const accLabel = ref([])
      const accTotal = ref([])
      const color = [
        '#ffbe0b',
        '#fb5607',
        '#ff006e',
        '#8338ec',
        '#3a86ff',
        '#e07a5f',
        '#3d405b',
        '#ffb3c1',
        '#81b29a',
        '#3c096c',
        '#9d4edd',
        '#8a817c',
        '#15616d',
        '#dcf763',
        '#780116',
        '#73ba9b',
        '#00a7e1',
        '#967aa1',
        '#0d00a4'
      ]
      const barChart = {
        type: 'bar',
        plugins: [ChartjsPluginStacked100, ChartDataLabels],
        options: {
          borderRadius: 8,
          borderWidth: 1,
          borderColor: '#ffffff',
          // events: ['click'],
          onClick: function (evt, item) {
            const Bardata = this.data.labels[item[0].index] + item[0].datasetIndex
            klikDataChart(Bardata)
          },
          indexAxis: 'x',
          plugins: {
            legend: {
              position: 'top'
            },
            tooltip: {
              callbacks: {
                title: function (tooltipItem, data, value) {
                  const tracker = tooltipItem[0].label
                  return `${tracker}`
                },
                label: function (tooltipItem, data, value) {
                  const tracker = tooltipItem.dataset.label + ': ' + tooltipItem.formattedValue + '% (' + currencyFormatter(tooltipItem.dataset.data[tooltipItem.parsed.x]) + ')'
                  return `${tracker}`
                }
              }
            },
            datalabels: {
              formatter: (_value, context) => {
                const data = context.chart.data
                const { datasetIndex, dataIndex } = context
                return `${data.calculatedData[datasetIndex][dataIndex]}%`
              },
              color: 'white',
              textAlign: 'center',
              font: {
                weight: 'bold',
                size: 14
              }
            },
            stacked100: {
              enable: true,
              precision: 2,
              replaceTooltipLabel: false
            }
          }
        },
        data: {
          labels: finLabel.value,
          datasets: [
            {
              label: accLabel.value,
              backgroundColor: color.value,
              data: accTotal.value
            }
          ]
        }
      }

      // Gauge Chart
      const gaugeNeedle = {
        id: 'gaugeNeedle',
        afterDatasetDraw (chart, args, options) {
          // eslint-disable-next-line no-unused-vars
          const { ctx, config, data, chartArea: { top, bottom, left, right, width, height } } = chart

          ctx.save()
          const djlValue = data.datasets[0].djlValue
          const needleValue = data.datasets[0].needleValue
          const dataTotal = data.datasets[0].data.reduce((a, b) => a + b, 0)
          const angle = Math.PI + (1 / dataTotal * needleValue * Math.PI)

          const cx = width / 2
          const cy = chart._metasets[0].data[0].y

          // needle
          ctx.translate(cx, cy)
          ctx.rotate(angle)
          ctx.beginPath()
          ctx.moveTo(0, -2)
          ctx.lineTo(chart._metasets[0].data[0].outerRadius, 0)
          ctx.lineTo(0, 2)
          ctx.fillStyle = '#444'
          ctx.fill()
          ctx.restore()

          // needle dot
          ctx.beginPath()
          ctx.arc(cx, cy, 5, 0, 10)
          ctx.fill()
          ctx.restore()

          ctx.font = '14px Helvetica'
          ctx.fillStyle = '#444'
          ctx.fillText(djlValue, cx, cy - 15)
          ctx.textAlign = 'center'
          ctx.restore()
        }
      }

      // Data Chart
      const filterDataChart = async (evt) => {

        isLoading.value = true
        await axiosEdu
        .get('chartall', { params: filter })
        .then(res => {
          // Pie
            const arrayLength = res.data.data.length
            const tes = []
            if (finLabel.value.length > 0) {
              finLabel.value = []
            }
            for (let i = 0; i < arrayLength; i++) {
              finLabel.value.push(res.data.data[i].incometype)
              tes.push(res.data.data[i].total)
            }

            dataBar.value = tes
            pieChart.data.labels = finLabel.value
            pieChart.data.datasets = [
              {
                backgroundColor: ['#1d2f6f', '#d80e4a', '#e0a206', '#782132', '#2db300', '#009999', '#4700b3', '#990099', '#669999'],
                data: dataBar.value
              }
            ]
            chartRefPie.value.render()
          // End
          // Bar
            const dataset = []
            const data = []
            const datapersen = []
            const dataall = res.data.data
            for (let x = 0; x < dataall.length; x++) {
              data.push(dataall[x].total)
              datapersen.push(dataall[x].persen)
            }

            const dtl = {
              label: 'Outstanding',
              backgroundColor: '#cc0000',
              data: data,
              djlValue: datapersen
            }
            dataset.push(dtl)

            const datapay = []
            const datapersenpay = []
            const dataallpay = res.data.dpayment
            for (let x = 0; x < dataallpay.length; x++) {
              datapay.push(dataallpay[x].payment)
              datapersenpay.push(dataallpay[x].persen)
            }

            const dtl2 = {
              label: 'Payment',
              backgroundColor: '#ff9999',
              data: datapay,
              djlValue: datapersenpay
            }
            dataset.push(dtl2)
            barChart.data.datasets = dataset

            chartRefBar.value.render()
          // End
          // Gauge Chart
            const datanumber = [parseInt(filter.chartfail), parseInt(filter.chartwarning), parseInt(filter.chartsuccess)]
            rowData.value = res.data.douchart

            setTimeout(() => {
              for (let i = 0; i < rowData.value.length; i++) {
                const dataValue = {
                  labels: ['Fail', 'Warning', 'Success'],
                  datasets: [{
                      label: 'Weekly Sales',
                      data: datanumber,
                      backgroundColor: [
                        'rgba(204, 0, 0, 1)',
                        'rgba(255, 204, 0, 1)',
                        'rgba(0, 204, 0, 1)'
                      ],
                      needleValue: res.data.douchart[i].persen,
                      djlValue: res.data.douchart[i].title,
                      info: res.data.douchart[i].info,
                      infopay: res.data.douchart[i].infopay,
                      borderColor: 'white',
                      borderWidth: 2,
                      cutout: '65%',
                      circumference: 180,
                      rotation: 270,
                      borderRadius: 5
                  }]
                }
                const config = {
                  type: 'doughnut',
                  data: dataValue,
                  options: {
                    plugins: {
                      legend: {
                        display: false
                      },
                      tooltip: {
                        yAlign: 'bottom',
                        displayColors: false,
                        callbacks: {
                          title: function (tooltipItem, data, value) {
                            const tracker = tooltipItem[0].dataset.djlValue
                            return `${tracker}`
                          },
                          beforeLabel: function (tooltipItem, data, value) {
                            const tracker = tooltipItem.dataset.info
                            return `${tracker}`
                          },
                          label: function (tooltipItem, data, value) {
                            const tracker = tooltipItem.dataset.infopay
                            // const tracker = tooltipItem.dataset.needleValue + '%'
                            return `${tracker}`
                          },
                          footer: function (tooltipItem, data, value) {
                            const tracker = tooltipItem[0].dataset.needleValue + '%'
                            return `${tracker}`
                          }
                        }
                      },
                      datalabels: {
                        formatter: (value, ctx) => {
                          let sum = 0
                          const dataArr = ctx.chart.data.datasets[0].data
                          // eslint-disable-next-line array-callback-return
                          dataArr.map(data => {
                              sum += data
                          })
                          const percentage = (value * 100 / sum).toFixed(0) + '%'
                          return percentage
                        },
                        color: 'white',
                        textAlign: 'center',
                        font: {
                          weight: 'bold',
                          size: 14
                        }
                      }
                    },
                    onClick: function (evt, item) {
                      klikDataChart(this.data.datasets[0].djlValue)
                    }
                  },
                  plugins: [gaugeNeedle, ChartDataLabels]
                }

                if (rowData.value[i].reuse) {
                  rowData.value[i].reuse.destroy()
                } else {
                  rowData.value[i].reuse = new Chart(
                    document.getElementById(res.data.douchart[i].id_canvas),
                    config
                  )
                }

              }
            }, 1000)

            isLoading.value = false
          // End
        }).catch(error => {
          isLoading.value = false

          console.log(error)
        })
      }

      // Siswa
      const filterDataSiswa = async (evt) => {
          isLoading.value = true

          await axiosEdu
          .get('chartsiswa', { params: filter })
          .then(res => {
            rowDataSiswa.value = res.data.data
            rowDataUploadVA.value = res.data.uploadva
            isLoading.value = false
          }).catch(error => {
            isLoading.value = false
            console.log(error)
          })
      }
      // End

      const changeValue = async (evt) => {
        localStorage.setItem('chartfail_url', filter.chartfail)
        localStorage.setItem('chartwarning_url', filter.chartwarning)
        localStorage.setItem('chartsuccess_url', filter.chartsuccess)

        if(rowData.value){
          for (let i = 0; i < rowData.value.length; i++) {
            rowData.value[i].reuse.destroy()
          }
        }

        if(chartRefPie.value){
          chartRefPie.value.destroy()
        }
        if(chartRefBar.value){
          chartRefBar.value.destroy()
        }

        setTimeout(() => {
          filterDataSiswa()
          filterDataSummary()
          filterDataChart()
        }, 500)
      }

      watch(() => [filter.cutoff], ([newVal, newLev]) => {
        changeValue()
        filterDataSummary()
      })

      onBeforeMount(() => {
        // if (localStorage.getItem('reloaded')) {
        //   localStorage.removeItem('reloaded')
        // } else {
        //   localStorage.setItem('reloaded', '1')
         // location.reload()
        // }

        localStorage.setItem('chartfail_url', 70)
        localStorage.setItem('chartwarning_url', 20)
        localStorage.setItem('chartsuccess_url', 10)
      })

      onMounted(() => {
        filterDataSiswa()
        fillChartData()
        filterDataSummary()

        filterDataChart()
      })
      const store = useStore()
      const clientBarItems = computed(() => store.state.clients.slice(0, 3))
      const transactionBarItems = computed(() => store.state.history.slice(0, 3))
      const darkMode = computed(() => store.state.darkMode)

      const klikData = async (evt) => {
        const cutoff = new Date(filter.cutoff).toISOString()

        isLoading.value = true
        localStorage.setItem('categoryid_url', evt.category_id)
        localStorage.setItem('departid_url', evt.department_id)
        localStorage.setItem('siswaid_url', evt.id)
        localStorage.setItem('cutoff_url', cutoff)

        localStorage.setItem('yearid_url', evt.nis)
        localStorage.setItem('levelid_url', evt.nis)
        localStorage.setItem('classid_url', evt.nis)

        window.open('/#/invoicetransaction', '_blank')
        isLoading.value = false
      }

      const klikDataChart = async (evt) => {
        const cutoff = new Date(filter.cutoff).toISOString()

        isLoading.value = true
        localStorage.setItem('departmentid_url', 'ALL')
        localStorage.setItem('cutoff_url', cutoff)
        localStorage.setItem('incometype_url', evt)

        const lastString = evt.substr(evt.length - 1)
        // if (Number.isInteger(lastString)) {
          if (lastString === 1 || lastString === '1') {
            window.open('/#/paymentdashboard', '_blank')
          } else {
            window.open('/#/ardashboard', '_blank')
          }
        // } else {
          // window.open('/#/ardashboard', '_blank')
        // }

        isLoading.value = false
      }

      const filterDataSummary = async (evt) => {
          await axiosEdu
          .get('chartsummary', { params: filter })
          .then(res => {
            // isLoading.value = false
          }).catch(error => {
              console.log(error)
          })
      }

      return {
        filterDataSiswa,
        filterDataSummary,
        filterDataChart,
        klikDataChart,
        changeValue,
        klikData,
        rowData,
        rowDataSiswa,
        rowDataUploadVA,
        barChart,
        chartRefBar,
        router,
        accLabel,
        accTotal,
        color,
        pieChart,
        dataBar,
        finLabel,
        groupFin,
        chartRefPie,
        isLoading,
        t,
        filter,
        titleStack,
        chartData,
        fillChartData,
        clientBarItems,
        transactionBarItems,
        darkMode,
        mdiAccountMultiple,
        mdiCartOutline,
        mdiChartTimelineVariant,
        mdiFinance,
        mdiMonitorCellphone,
        mdiReload,
        mdiGithub,
        mdiMagnify,
        isAsideMobileExpanded
      }
    }
  }
  function currencyFormatter (currency, sign) {
      if (currency !== undefined) {
          const sansDec = Number(currency).toFixed()
          const formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          return `${formatted}`
      }
  }
</script>

<style>
.full-page-container{
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
}
.dashboard-container{
  background-color: white;
  padding: 15px;
  box-shadow: 0px 3px 8px rgba(0,0,0,0.25);
}
.outstanding-wrapper{
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.cutoff-container{
  display: flex;
}
.cutoff{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 50px;
}
.cutoff label{
  width: fit-content;
  white-space: nowrap;
}
.cutoff label .dp__main.dp__theme_light div[aria-label="Datepicker input"] .dp__input_wrap input.dp__input{
  border-bottom: 0 solid !important;
  box-shadow: 0px 1px 2px #F67638 !important;
  border: 1px solid #F67638
}

.category-container{
  display: grid;
  font-weight: bold;
  grid-template-columns: auto auto;
  gap: 45px;
  margin: 1rem 0;
  /* border-bottom: 1.25px solid #1c3677; */
}
.select-box{
  display: flex;
  justify-content: space-between;
}
.category-container .select-box div.flex label.checkbox span.check{
  border: 1px solid #F67638;
  margin-right: 3px;
  border-radius: 50%;
}
.category-container .select-box div.flex label.checkbox input[type=checkbox]:checked+.check{
  background-image: none !important;
}
.category-container .select-box div.flex label.checkbox input[type=checkbox]:checked+.check, .radio input[type=radio]:checked+.check{
    background-color: #56b534;
    background-position: center;
    background-repeat: no-repeat;
}

hr.divider{
  border-color: #1c3677;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
  border-width: 1px;
}
.payment-dashboard{
  margin: 1rem 0 ;
}
.payment-box{
  background: #c9fad3 !important;
  color: black !important;
  border: 2px solid #56b534;
}
.payment-box div.flex-1{
  padding: 5px 10px;
}
.payment-box div.flex-1 div{
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}
.payment-box div.flex-1 div div div div div{
  flex-direction: column-reverse;
}
.payment-box div.flex-1 div div div div p{
  font-size: 14px;
  margin-top: 0 !important;
}
.payment-box div.flex-1 div div div div h4{
  font-weight: 600 !important;
}
.payment-box div.flex-1 div div div{
  flex-direction: column-reverse;
}
.payment-box div.flex-1 div.justify-between div.flex.items-center.justify-center.mb-6 {
  width: 100% !important;
}
.payment-box div.flex-1 div.justify-between div.flex.items-center.justify-center.mb-6 div.justify-start.items-center.block{
  width: 100% !important;
  padding-bottom: 10px;
  border-bottom: 2px solid #56b534;
}
.payment-box div.flex-1 div div div p{
  font-size: 14px;
  margin-top: 10px !important;
}
.payment-box div.flex-1 div div div h4{
  font-weight: 600 !important;
}

.invoice-box{
  background: white !important;
  color: black !important;
  border: 2px solid black;
}
.invoice-box div.flex-1{
  padding: 5px 10px;
}
.invoice-box div.flex-1 div{
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}
.invoice-box div.flex-1 div div div div div{
  flex-direction: column-reverse;
}
.invoice-box div.flex-1 div div div div p{
  font-size: 14px;
  margin-top: 0 !important;
}
.invoice-box div.flex-1 div div div div h4{
  font-weight: 600 !important;
}
.invoice-box div.flex-1 div div div{
  flex-direction: column-reverse;
}
.invoice-box div.flex-1 div.justify-between div.flex.items-center.justify-center.mb-6 {
  width: 100% !important;
}
.invoice-box div.flex-1 div.justify-between div.flex.items-center.justify-center.mb-6 div.justify-start.items-center.block{
  width: 100% !important;
  padding-bottom: 10px;
  border-bottom: 2px solid black;
}
.invoice-box div.flex-1 div div div p{
  font-size: 14px;
  margin-top: 10px !important;
}
.invoice-box div.flex-1 div div div h4{
  font-weight: 600 !important;
}

.debtors-container{
  overflow-y: auto;
  max-height: 550px;
}
.debtors-container::-webkit-scrollbar{
  width: 0;
}
.debtors-container div.transition-shadow {
  background: #FFEEEE;
  border: 2px solid #eb2929;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
  margin-bottom: 15px;
}
.debtors-container div.transition-shadow div.flex-1{
  padding: 5px 10px;
}
.debtors-container div.transition-shadow div.flex-1 div.block {
  display: flex;
  justify-content: left;
}
.date-picker div[aria-label="Datepicker input"] div.dp__input_wrap input.dp__pointer{
  background: white;
  border: 1px solid #F67638;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
  padding: 3px;
  padding-left: 35px;
  border-radius: 0px;
}
.rate-container{
  background: white;
  padding: 15px;
  margin-top: 30px;
  box-shadow: 0px 3px 8px rgba(0,0,0,0.25);
}
.input-rate div.mb-6 div div.grid div.form-comp input[type="number"]{
  border:1px solid #F67638;
}
</style>

<script setup>
import { computed, ref } from 'vue'
import {
  mdiCashMinus,
  mdiCashPlus,
  mdiReceipt,
  mdiCreditCardOutline
} from '@mdi/js'
import CardBox from '@/components/CardBox.vue'
import BaseLevel from '@/components/BaseLevel.vue'
// import PillTag from '@/components/PillTag.vue'
import IconRounded from '@/components/IconRounded.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

const props = defineProps({
  nis: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  kelas: {
    type: String,
    required: true
  },
  value: {
    type: Number,
    required: true
  },
  cutoff: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  },
  category_id: {
    type: String,
    required: true
  },
  department_id: {
    type: String,
    required: true
  },
  jml_inv: {
    type: String,
    required: true
  },
  min_inv: {
    type: String,
    required: true
  },
  max_inv: {
    type: String,
    required: true
  }
})

const icon = computed(() => {
  if (props.type === 'withdrawal') {
    return {
      icon: mdiCashMinus,
      type: 'danger'
    }
  } else if (props.type === 'deposit') {
    return {
      icon: mdiCashPlus,
      type: 'success'
    }
  } else if (props.type === 'invoice') {
    return {
      icon: mdiReceipt,
      type: 'warning'
    }
  }

  return {
    icon: mdiCreditCardOutline,
    type: 'info'
  }
})

const isLoading = ref(false)
const klikData = async () => {
  const cutoff = new Date(props.cutoff).toISOString()

  isLoading.value = true
  localStorage.setItem('categoryid_url', props.category_id)
  localStorage.setItem('departid_url', props.department_id)
  localStorage.setItem('siswaid_url', props.id)
  localStorage.setItem('cutoff_url', cutoff)

  localStorage.setItem('yearid_url', props.nis)
  localStorage.setItem('levelid_url', props.nis)
  localStorage.setItem('classid_url', props.nis)

  window.open('/#/invoicetransaction', '_blank')
  isLoading.value = false
}

</script>
<style scoped>
 tbody.debtors tr td{
  padding: 0 !important;
  font-size: 14px !important;
 }
</style>

<template>
  <div class="vld-parent">
    <loading v-model:active="isLoading" :is-full-page="true" :height="128" :width="128"/>
  </div>

  <CardBox class="mb-2 border-2 last:mb-0" is-hoverable @click="klikData()">
    <BaseLevel>
        <IconRounded :icon="icon.icon" :color="icon.type" class="hidden md:mr-3" />
        <div class="text-left space-y-1 md:mr-3">
          <p class="mb-4">
            <b>{{ name }}</b> - ({{ nis }} | {{ kelas }})
          </p>
          <table>
            <tbody class="debtors">
              <tr>
                <td class="w-fit">Min Age</td>
                <td>:</td>
                <td><b class="ml-2">{{ min_inv }} {{ $t('templateemail.days_ago') }}</b></td>
              </tr>
              <tr>
                <td class="w-fit">Max Age</td>
                <td>:</td>
                <td><b class="ml-2">{{ max_inv }} {{ $t('templateemail.days_ago') }}</b></td>
              </tr>
              <tr>
                <td class="w-fit">Invoice</td>
                <td>:</td>
                <td><b class="ml-2">{{ value }} | {{ jml_inv }} Invoice</b></td>
              </tr>
          </tbody>
        </table>
      </div>
    </BaseLevel>
  </CardBox>
</template>

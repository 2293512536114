import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import i18n from './i18n'
import axios from 'axios'
import Toast, { POSITION } from 'vue-toastification'
import { vfmPlugin } from 'vue-final-modal'
import { defineRule } from 'vee-validate'
import { setHeaderToken } from './utils/auth'
// import CountryFlag from 'vue-country-flag-next'
import VueProgressBar from '@aacassandra/vue3-progressbar'
import FloatingVue from 'floating-vue'
import logoutPlugin from 'vue-auto-logout'
import EasySlider from 'vue-easy-slider'
import 'vue-toastification/dist/index.css'
import '../node_modules/vue-select/dist/vue-select.css'

// Table
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

// Styling CSS
import './css/main.css'

axios.defaults.baseURL = 'https://project-demo.amatraedu.com:8002/api/v1/'

/* Dark mode */
const token = localStorage.getItem('token')
if (token) {
  setHeaderToken(token)
}

/* Collapse mobile aside menu on route change */
router.beforeEach(to => {
  store.dispatch('asideMobileToggle', false)
  store.dispatch('asideLgToggle', false)
})

/* Default title tag */
const defaultDocumentTitle = 'AMATRA PROJECT ADMINISTRATION'

router.afterEach(to => {
  /* Set document title from route meta */
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }

  /* Full screen mode */
  store.dispatch('fullScreenToggle', !!to.meta.fullScreen)
})

// global validation
defineRule('required', value => {
  if (!value || !value.length) {
    return 'This field is required'
  }
  return true
})

defineRule('email', value => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }

  // Check if email
  if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/.test(value)) {
    return 'This field must be a valid email'
  }

  return true
})

// options NPprogressbar
const options = {
  color: '#bffaf3',
  failedColor: '#874b4b',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 1300
  },
  autoRevert: true,
  location: 'top',
  autoFinish: false,
  position: 'absolute'
}
const logoutOptions = {
  stagnateTime: 30 * 60 * 1000,
  detectTime: 30 * 60
}

store.dispatch('get_user', token)
  .then(() => {
    createApp(App)
      .use(i18n)
      .use(FloatingVue)
      .use(VueProgressBar, options)
      // .use(CountryFlag)
      .use(vfmPlugin({
        key: '$vfm',
        componentName: 'VueFinalModal',
        dynamicContainerName: 'ModalsContainer'
      }))
      .use(router, axios)
      .use(store)
      .use(Toast, { position: POSITION.BOTTOM_RIGHT })
      .use(logoutPlugin, logoutOptions)
      .use(EasySlider)
      .mount('#app')
  }).catch((error) => {
    console.error(error)
  })

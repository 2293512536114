<template>
  <div class="relative form-comp">
    <select
      v-if="computedType === 'select'"
      v-model="computedValue"
      :name="name"
      :multiple="multiple"
      :disabled="disabled"
      :id="id"
      :required="required"
      :class="inputElClass"
      class="input-form-select">
      <option :class="darkMode ? 'bg-darkmode-hard' : 'bg-white'" :value="''" disabled selected><span style="dark:placeholder-gray-400"> {{ placeholder }}</span></option>
      <option :class="darkMode ? 'bg-darkmode-hard' : 'bg-white'" v-for="option in options" :key="option.id ?? option" :value="option.id ?? option" :selected="option.id === '6a23c1e2-74c8-4fa4-a775-25bf14178adb'">{{ option.label ?? option.name ?? option.building_name ??  option.room_name ??  option.description  ?? option.authority ?? option.employee_name ?? option.time ?? option.invoice_no +" - "+option.outstanding ?? option.description ?? option.year_description ??  option.id ??option }}</option>
    </select>
    <textarea
      v-else-if="computedType === 'textarea'"
      v-model="computedValue"
      :class="inputElClass"
      :disabled="disabled"
      :name="name"
      :id="id"
      :placeholder="placeholder"
      :required="required"
      class="input-form"
    ></textarea>
    <input
      v-else-if="computedType === 'currency'"
      ref="inputRef"
      v-model="computedValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :name="name"
      :prosem="prosem"
      :autocomplete="autocomplete"
      :required="required"
      :disabled="disabled"
      :id="id"
      :step="step"
      :placeholder="placeholder"
      :readonly="readonly"
      type="text"
      :class="icon ? 'pl-10' : ''"
      class="input-form"
    >
    <input
      v-else
      ref="inputEl"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :name="name"
      :prosem="prosem"
      :autocomplete="autocomplete"
      :required="required"
      :disabled="disabled"
      :id="id"
      :min="min"
      :max="max"
      :step="step"
      :placeholder="placeholder"
      :readonly="readonly"
      :type="computedType"
      :class="icon ? 'pl-10' : ''"
      class="input-form"
    >
    <control-icon
      v-if="icon"
      :icon="icon"
      :h="controlIconH"
      class="input-icon"
    />
  </div>
</template>

<script>
import { computed, ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { useStore } from 'vuex'
import { useCurrencyInput } from 'vue-currency-input'

import ControlIcon from '@/components/ControlIcon'

export default {
  name: 'Control',
  components: {
    ControlIcon
  },
  props: {
    name: String,
    id: String,
    required: Boolean,
    disabled: Boolean,
    multiple: Boolean,
    step: String,
    autocomplete: String,
    placeholder: String,
    readonly: String,
    icon: String,
    options: Array,
    min: Number,
    max: Number,
    optionsCurrency: Object,
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: [String, Number, Boolean, Array, Object],
      default: ''
    },
    prosem: {
      type: [Array, Object]
    },
    borderless: Boolean,
    transparent: Boolean,
    hasError: Boolean,
    hasSuccess: Boolean,
    ctrlKFocus: Boolean
  },
  emits: ['update:modelValue', 'right-icon-click', 'prosemData'],
  setup (props, { emit }) {
    const computedValue = computed({
      get: () => props.modelValue,
      set: value => {
        emit('update:modelValue', value)
      }
    })

    if (props.prosem !== undefined) {
      emit('prosemData', [props.prosem[1], props.prosem[0][0].substr(0, 4), getMonthFromString(props.prosem[0][2])])
    }

    function getMonthFromString (mon) {
      const d = Date.parse(mon + '1, 2012')
      if (!isNaN(d)) {
        return new Date(d).getMonth() + 1
      }
      return -1
    }

    const inputElClass = computed(() => {
      const base = [
        'focus:ring focus:outline-none ring-red-600 ring-offset-2',
        'dark:placeholder-gray-400',
        computedType.value === 'textarea' ? 'h-24' : 'h-12',
        props.multiple ? 'h-24' : 'h-12',
        props.borderless ? 'border-0' : 'border',
        props.transparent ? 'bg-transparent' : 'bg-white dark:bg-gray-800',
        props.disabled ? 'disable-input-control' : '',
        props.hasError ? 'border-red-500' : (props.hasSuccess ? 'border-green-500' : 'border-gray-500')
      ]

      if (props.icon) {
        base.push('pl-10')
      }

      return base
    })

    const computedType = computed(() => props.options ? 'select' : props.type)

    const controlIconH = computed(() => props.type === 'textarea' ? 'h-full' : 'h-full')

    const store = useStore()

    const darkMode = computed(() => store.state.darkMode)

    const inputEl = ref(null)

    const { inputRef, setOptions } = useCurrencyInput(props.optionsCurrency)

    // watch(
    //   () => props.modelValue, // Vue 2: props.value
    //   (value) => {
    //       emit('update:modelValue', value)
    //     setValue(value)
    //   }
    // )

    watch(
      () => props.optionsCurrency,
      (optionsCurrency) => {
        setOptions(optionsCurrency)
      }
    )

    if (props.ctrlKFocus) {
      const fieldFocusHook = e => {
        if (e.ctrlKey && e.key === 'k') {
          e.preventDefault()
          inputEl.value.focus()
        } else if (e.key === 'Escape') {
          inputEl.value.blur()
        }
      }

      onMounted(() => {
        if (!store.state.isFieldFocusRegistered) {
          window.addEventListener('keydown', fieldFocusHook)

          store.commit('basic', {
            key: 'isFieldFocusRegistered',
            value: true
          })
        } else {
          console.error('Duplicate field focus event')
        }
      })

      onBeforeUnmount(() => {
        window.removeEventListener('keydown', fieldFocusHook)

        store.commit('basic', {
          key: 'isFieldFocusRegistered',
          value: false
        })
      })
    }

    return {
      computedValue,
      getMonthFromString,
      inputElClass,
      computedType,
      controlIconH,
      inputEl,
      inputRef,
      darkMode
    }
  }
}
</script>

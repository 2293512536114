<template>
  <!-- <footer v-show="isFooterBarVisible" class="footer-parent" :style="[darkMode ? 'background-color: #272727; color: white;' : 'background-color: white; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; color: black;']"> -->
  <footer class="footer-parent transition-all duration-1000" :style="[darkMode ? 'background-color: #272727; color: white;' : 'background-color: white; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; color: black;']">
    &copy; 2021,<a href="" target="_blank">&nbsp;Amatra Education</a>
  </footer>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
// import Level from '@/components/Level'
// import JbLogo from '@/components/JbLogo'

export default {
  name: 'FooterBar',
  components: {
    // Level
    // JbLogo
  },
  setup () {
    const isAsideMobileExpanded = computed(() => store.state.isAsideMobileExpanded)

    const darkMode = computed(() => store.state.darkMode)

    const store = useStore()

    const year = computed(() => new Date().getFullYear())

    const isFooterBarVisible = computed(() => !store.state.isFullScreen)

    return {
      year,
      darkMode,
      store,
      isFooterBarVisible,
      isAsideMobileExpanded
    }
  }
}
</script>
